<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, index) in list"
        :key="'s'+index"
        cols="4"
        sm="3"
        lg="2"
        class="pa-1 pa-sm-2"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            flat
            color="box_bg"
            :to="'/p/'+item.id"
          >
            <v-img
              :src="item.logo"
              :aspect-ratio="1/1"
            >
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  opacity="0.6"
                  class="text-center"
                >
                  <v-btn
                    small
                    fab
                    color="primary"
                  >
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                  <div>Play</div>
                </v-overlay>
              </v-fade-transition>
            </v-img>
            <v-card-title
              class="text-body-2 pa-2 d-flex justify-space-between"
            >
              <span
                class="text-truncate"
              >
                {{ item.name }}
              </span>
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'HomeSlots',
  components:{
  },
  props: {

  },
  data: () => ({
    list: [],
  }),
  watch:{

  },
  created() {
    
  },
  mounted() {
    this.home_slots()
  },
  methods: {
    home_slots() {
      let paramObj = {

      }
      this.$server.home_slots(paramObj).then((response) => {
        if(response.code==200){
          this.list = response.data.data
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
  }
};
</script>