<template>
  <div>
    <div>
      <v-subheader class="px-0">
        <h4>
          <v-icon color="red">
            mdi-fire
          </v-icon>
          {{ $t('rmyx') }}
        </h4>
        <v-spacer />
        <v-btn
          depressed
          small
          class="text-none btn_bg"
          to="/hot"
        >
          {{ $t('jzgd') }}
        </v-btn>
      </v-subheader>

      <v-row dense>
        <v-col
          v-for="(item, index) in hotSlides"
          :key="'hot_'+index"
          cols="4"
          md="2"
        >
          <v-card
            flat
            color="box_bg"
            @click="gotogame(item)"
          >
            <v-img
              :src="item.logo"
              :aspect-ratio="1/1"
            />
            <v-card-title
              class="text-caption pa-2 text-truncate"
            >
              {{ item.name }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-subheader class="px-0">
        <h4>
          <v-icon color="blue">
            mdi-slot-machine
          </v-icon>
          {{ $t('slots') }}
        </h4>
        <v-spacer />
        <v-btn
          depressed
          small
          class="text-none btn_bg"
          to="/slots"
        >
          {{ $t('jzgd') }}
        </v-btn>
      </v-subheader>

      <v-row dense>
        <v-col
          v-for="(item, index) in slotsSlides"
          :key="'slots_'+index"
          cols="4"
          md="2"
        >
          <v-card
            flat
            color="box_bg"
            :to="'/p/'+item.id"
          >
            <v-img
              :src="item.logo"
              :aspect-ratio="1/1"
            />
            <v-card-title
              class="text-caption pa-2 text-truncate"
            >
              {{ item.name }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-sheet
      color="box_bg"
      class="rounded-lg mt-3"
    >
      <v-chip-group
        mandatory
        color="primary"
        class="px-2 rounded-lg font-weight-medium"
      >
        <v-chip
          color="app_bg"
          @click="changechip('all')"
        >
          <v-icon
            left
            small
          >
            mdi-gamepad-circle
          </v-icon>
          {{ $t('qbtz') }}
        </v-chip>
        <v-chip
          color="app_bg"
          @click="changechip('winner')"
        >
          <v-icon
            left
            small
          >
            mdi-bookmark-check
          </v-icon>
          {{ $t('dayingjia') }}
        </v-chip>
        <v-chip
          color="app_bg"
          @click="changechip('bigaward')"
        >
          <v-icon
            left
            small
          >
            mdi-fire
          </v-icon>
          {{ $t('gbdj') }}
        </v-chip>
      </v-chip-group>
      
      <div
        v-show="showall"
      >
        <v-row
          no-gutters
          class="caption text--disabled pa-2"
        >
          <v-col>
            {{ $t('youxi') }}
          </v-col>
          <v-col>
            {{ $t('wanjia') }}
          </v-col>
          <v-col
            cols="2"
            class="d-none d-sm-flex"
          >
            {{ $t('touzhu') }}(USD)
          </v-col>
          <v-col
            cols="2"
            md="1"
          >
            <span class="ml-n8">{{ $t('beishu') }}</span>
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-right"
          >
            <span class="ml-n1">{{ $t('shuying') }}</span>
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in $store.state.betlog"
          :key="'betlog'+index"
          class="body-2 bet-list pa-2 py-3"
          no-gutters
        >
          <v-col
            class="text-truncate text--disabled text-caption"
          >
            {{ item.game_name }}
          </v-col>
          <v-col
            class="text-truncate"
          >
            {{ item.username }}
          </v-col>
          <v-col
            cols="2"
            class="d-none d-sm-flex"
          >
            <strong>{{ item.bet_amount }}</strong>
          </v-col>
          <v-col
            cols="2"
            md="1"
          >
            <small class="text--disabled">{{ item.result==1?(item.multiple+'x'):'-' }}</small>
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-right"
          >
            <strong :class="(item.result==1?'green--text':'red--text') + ' ml-1'">{{ item.result==1?'+':'-' }}{{ item.winloss }}</strong>
          </v-col>
        </v-row>
      </div>
      <div
        v-show="showwinner"
      >
        <v-row
          no-gutters
          class="caption text--disabled pa-2"
        >
          <v-col>
            {{ $t('youxi') }}
          </v-col>
          <v-col>
            {{ $t('wanjia') }}
          </v-col>
          <v-col
            cols="2"
            class="d-none d-sm-flex"
          >
            {{ $t('touzhu') }}(USD)
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-no-wrap"
          >
            {{ $t('beishu') }}
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-right"
          >
            {{ $t('shuying') }}
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in $store.state.winner"
          :key="'betlog'+index"
          class="body-2 bet-list pa-2 py-3"
          no-gutters
        >
          <v-col
            class="text-truncate text--disabled text-caption"
          >
            {{ item.game_name }}
          </v-col>
          <v-col
            class="text-truncate"
          >
            {{ item.username }}
          </v-col>
          <v-col
            cols="2"
            class="d-none d-sm-flex"
          >
            <strong>{{ item.bet_amount }}</strong>
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-no-wrap"
          >
            {{ item.result==1?(item.multiple+'x'):'-' }}
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-right"
          >
            <strong :class="(item.result==1?'green--text':'red--text') + ' ml-1'">{{ item.result==1?'+':'-' }}{{ item.winloss }}</strong>
          </v-col>
        </v-row>
      </div>
      <div
        v-show="showbigaward"
      >
        <v-row
          no-gutters
          class="caption text--disabled pa-2"
        >
          <v-col>
            {{ $t('youxi') }}
          </v-col>
          <v-col>
            {{ $t('wanjia') }}
          </v-col>
          <v-col
            cols="2"
            class="d-none d-sm-flex"
          >
            {{ $t('touzhu') }}(USD)
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-no-wrap"
          >
            {{ $t('beishu') }}
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-right"
          >
            {{ $t('shuying') }}
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in $store.state.bigaward"
          :key="'betlog'+index"
          class="body-2 bet-list pa-2 py-3"
          no-gutters
        >
          <v-col
            class="text-truncate text--disabled text-caption"
          >
            {{ item.game_name }}
          </v-col>
          <v-col
            class="text-truncate"
          >
            {{ item.username }}
          </v-col>
          <v-col
            cols="2"
            class="d-none d-sm-flex"
          >
            <strong>{{ item.bet_amount }}</strong>
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-no-wrap"
          >
            {{ item.result==1?(item.multiple+'x'):'-' }}
          </v-col>
          <v-col
            cols="2"
            md="1"
            class="text-right"
          >
            <strong :class="(item.result==1?'green--text':'red--text') + ' ml-1'">{{ item.result==1?'+':'-' }}{{ item.winloss }}</strong>
          </v-col>
        </v-row>
      </div>
    </v-sheet>

    <div class="pt-4">
      <div class="d-flex justify-center flex-wrap text-body-2">
        <a
          v-for="(item, index) in articlelist"
          :key="'art_'+index"
          class="ma-2 grey--text"
          @click="showabout(item)"
        >{{ item.title }}</a>
      </div>
      <v-divider
        class="my-2 opacity-3"
      />
      <div class="d-flex justify-center flex-wrap">
        <span
          v-for="n in 1"
          :key="'top_'+n"
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/pix.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/visa-col.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/mc-col.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/BCB.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/Meta.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/Google-Cloud.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2"
        >
          <img
            src="../../assets/img/logos/brand/AWS-web.svg"
            height="20px"
            class="mx-auto"
          >
        </span>
      </div>
      <v-divider
        class="my-2 opacity-3"
      />

      <div class="d-flex justify-center flex-wrap">
        <span
          class="d-flex align-center pa-2 opacity-5"
          style="filter: grayscale(100%);"
        >
          <img
            src="../../assets/img/logos/providers/pgsoft-logo.svg"
            height="24px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2 opacity-5"
          style="filter: grayscale(100%);"
        >
          <img
            src="../../assets/img/logos/providers/evolution-logo.svg"
            height="24px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2 opacity-5"
          style="filter: grayscale(100%);"
        >
          <img
            src="../../assets/img/logos/providers/pragmaticplay-logo.svg"
            height="24px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2 opacity-5"
          style="filter: grayscale(100%);"
        >
          <img
            src="../../assets/img/logos/providers/bgaming-logo.svg"
            height="24px"
            class="mx-auto"
          >
        </span>
        <span
          class="d-flex align-center pa-2 opacity-5"
          style="filter: grayscale(100%);"
        >
          <img
            src="../../assets/img/logos/providers/spribe-logo.svg"
            height="24px"
            class="mx-auto"
          >
        </span>
      </div>
      <v-divider
        class="my-2 opacity-3"
      />
      <p class="text-body-2 pa-3 mb-0 opacity-2">
        YeloBet.com é operada pela Long Island N.V., número de registro da empresa 157372, com endereço registrado em Zuikertuintjeweg Z/N (Zuikertuin Tower) Curaçao e é licenciada e autorizada pelo governo de Curaçao. A YeloBet.com opera sob a Master License of Gaming Services Provider, N.V. Número da Licença: GLH-OCCHKTW0705152022
        <br><br>
        Este produto é para uso de usuários maiores de 18 anos e é destinado apenas para fins de entretenimento.
      </p>
    </div>
    <v-dialog
      v-model="about"
      max-width="600"
      scrollable
    >
      <v-card
        color="box_bg"
      >
        <v-card-title class="text-h6">
          {{ article.title }}
        </v-card-title>

        <v-card-text
          style="height: 500px;"
          v-html="article.content"
        />

        <v-divider class="opacity-3" />

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="about = false"
          >
            {{ $t('guanbi') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="showdialog">
      <Login
        v-model="loginreg"
        @closeloginreg="closeloginreg"
        @showforget="showforget"
      />
    </template>
    <template v-if="forget">
      <Forget
        @closeforget="closeforget"
      />
    </template>
  </div>
</template>
<script>
import 'swiper/css/swiper.css'
import Login from '@/components/Login.vue'
import Forget from '@/components/Forget.vue'
export default {
  name: 'HomeLobby',
  components:{
    Login,
    Forget
  },
  props: {

  },
  data: () => ({
    showdialog: false,
    loginreg: false,
    forget: false,
    player_id: 0,
    showall: true,
    showwinner: false,
    showbigaward: false,
    hotSlides: [],
    pgSlides: [],
    slotsSlides: [],
    casinoSlides: [],
    hotSlides: 6,
    pgSlides: 6,
    slotsSlides: 6,
    casinoSlides: 6,
    hot_btn_prev_disable: false,
    hot_btn_next_disable: false,
    pg_btn_prev_disable: false,
    pg_btn_next_disable: false,
    slots_btn_prev_disable: false,
    slots_btn_next_disable: false,
    casino_btn_prev_disable: false,
    casino_btn_next_disable: false,
    swiperHotGames: {
      slidesPerView: 'auto',
      spaceBetween: 16,
      mousewheel: false,
      observer: true,
      observeParents: true,
      breakpoints: {
        360: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 8,
          centeredSlides: false
        },
        640: {
          slidesPerGroup: 5,
          slidesPerView: 5,
          spaceBetween: 16,
          centeredSlides: false
        },
        960: {
          slidesPerGroup: 6,
          slidesPerView: 6,
          centeredSlides: false
        }
      },
      pagination: {
        el: '.swiper-hot-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiperHotGames-button-next',
        prevEl: '.swiperHotGames-button-prev'
      }
    },
    swiperPgGames: {
      slidesPerView: 'auto',
      spaceBetween: 16,
      mousewheel: false,
      observer: true,
      observeParents: true,
      breakpoints: {
        360: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 8,
          centeredSlides: false
        },
        640: {
          slidesPerGroup: 5,
          slidesPerView: 5,
          spaceBetween: 16,
          centeredSlides: false
        },
        960: {
          slidesPerGroup: 6,
          slidesPerView: 6,
          centeredSlides: false
        }
      },
      pagination: {
        el: '.swiper-pg-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiperPgGames-button-next',
        prevEl: '.swiperPgGames-button-prev'
      }
    },
    swiperSlotsGames: {
      slidesPerView: 'auto',
      spaceBetween: 16,
      mousewheel: false,
      observer: true,
      observeParents: true,
      breakpoints: {
        360: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 8,
          centeredSlides: false
        },
        640: {
          slidesPerGroup: 5,
          slidesPerView: 5,
          spaceBetween: 16,
          centeredSlides: false
        },
        960: {
          slidesPerGroup: 6,
          slidesPerView: 6,
          centeredSlides: false
        }
      },
      pagination: {
        el: '.swiper-slots-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiperSlotsGames-button-next',
        prevEl: '.swiperSlotsGames-button-prev'
      }
    },
    swiperCasinoGames: {
      slidesPerView: 'auto',
      spaceBetween: 16,
      mousewheel: false,
      observer: true,
      observeParents: true,
      breakpoints: {
        360: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          spaceBetween: 8,
          centeredSlides: false
        },
        640: {
          slidesPerGroup: 5,
          slidesPerView: 5,
          spaceBetween: 16,
          centeredSlides: false
        },
        960: {
          slidesPerGroup: 6,
          slidesPerView: 6,
          centeredSlides: false
        }
      },
      pagination: {
        el: '.swiper-casino-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiperCasinoGames-button-next',
        prevEl: '.swiperCasinoGames-button-prev'
      }
    },
    about: false,
    articlelist: [],
    article: {},
  }),
  computed: {

  },
  watch: {
    '$store.state.betlog': function (newVal) {

    },
    '$store.state.winner': function (newVal) {

    },
    '$store.state.bigaward': function (newVal) {

    },
  },
  created() {
    this.home_lobby()
  },
  mounted() {
    if(this.getStorage('player_id')){
      this.player_id = this.getStorage('player_id')
    }

    this.articles()
  },
  methods: {
    gotogame(item) {
      if(this.player_id>0){
        if(item.maintain==1){
          this.$snackbar.warning('maintenance')
        }else{
          this.$router.push('/game/'+item.id)
        }
      }else{
        this.showloginreg('login')
      }
    },
    showloginreg(tab) {
      this.loginreg = tab
      this.showdialog = true
    },
    closeloginreg() {
      this.loginreg = false
      this.showdialog = false
    },
    showforget() {
      this.forget = true
    },
    closeforget() {
      this.forget = false
    },
    home_lobby() {
      let paramObj = {
        player_id: this.getStorage('player_id'),
      }
      this.$server.home_lobby(paramObj).then((response) => {
        if(response.code==200){
          this.hotSlides = response.data.hot_games
          this.pgSlides = response.data.pg_games
          this.slotsSlides = response.data.slots
          this.casinoSlides = response.data.live
        }else{
          
        }
      })
    },
    changechip(selchip){
      if(selchip=='all'){
        this.showall = true
        this.showwinner = false
        this.showbigaward = false
      }
      if(selchip=='winner'){
        this.showall = false
        this.showwinner = true
        this.showbigaward = false
      }
      if(selchip=='bigaward'){
        this.showall = false
        this.showwinner = false
        this.showbigaward = true
      }
    },
    changetab(tab) {
      this.$emit("changechip", tab)
    },
    slideHotChange () {
      console.log(this.$refs.hotSwiper.$swiper.activeIndex)
      if(this.$refs.hotSwiper.$swiper.isBeginning){
        this.hot_btn_prev_disable = true
      }else{
        this.hot_btn_prev_disable = false
      }
      if(this.$refs.hotSwiper.$swiper.isEnd){
        this.hot_btn_next_disable = true
      }else{
        this.hot_btn_next_disable = false
      }
    },
    slidePgChange () {
      console.log(this.$refs.pgSwiper.$swiper.activeIndex)
      if(this.$refs.pgSwiper.$swiper.isBeginning){
        this.pg_btn_prev_disable = true
      }else{
        this.pg_btn_prev_disable = false
      }
      if(this.$refs.pgSwiper.$swiper.isEnd){
        this.pg_btn_next_disable = true
      }else{
        this.pg_btn_next_disable = false
      }
    },
    slideSlotsChange () {
      console.log(this.$refs.slotsSwiper.$swiper.activeIndex)
      if(this.$refs.slotsSwiper.$swiper.isBeginning){
        this.slots_btn_prev_disable = true
      }else{
        this.slots_btn_prev_disable = false
      }
      if(this.$refs.slotsSwiper.$swiper.isEnd){
        this.slots_btn_next_disable = true
      }else{
        this.slots_btn_next_disable = false
      }
    },
    slideCasinoChange () {
      console.log(this.$refs.casinoSwiper.$swiper.activeIndex)
      if(this.$refs.casinoSwiper.$swiper.isBeginning){
        this.casino_btn_prev_disable = true
      }else{
        this.casino_btn_prev_disable = false
      }
      if(this.$refs.casinoSwiper.$swiper.isEnd){
        this.casino_btn_next_disable = true
      }else{
        this.casino_btn_next_disable = false
      }
    },
    fav(item) {
      if(this.getStorage('player_id')<1){
        return false
      }
      if(item.fav){
        this.favcancel(item.id)
      }else{
        this.favadd(item.id)
      }
      item.fav = !item.fav
    },
    favadd(game_id) {
      let paramObj = {
        player_id: this.getStorage('player_id'),
        game_id:game_id
      }
      this.$server.favadd(paramObj).then((response) => {
        if(response.code==200){
          
        }else{
          
        }
      })
    },
    favcancel(game_id) {
      let paramObj = {
        player_id: this.getStorage('player_id'),
        game_id:game_id
      }
      this.$server.favcancel(paramObj).then((response) => {
        if(response.code==200){
          
        }else{
          
        }
      })
    },
    articles() {
      let paramObj = {
        
      }
      this.$server.articles(paramObj).then((response) => {
        if(response.code==200){
          this.articlelist = response.data
        }
      })
    },
    showabout(item) {
      this.article = item
      this.about = true
    },
  },
};
</script>

<style>
.bet-list:nth-child(2n) {
  background: var(--v-nav_bg-base);
}
</style>