<template>
  <v-container>
    <swiper
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(item, index) in topBanners"
        :key="'b_'+index"
      >
        <div
          v-if="item.id==12"
        >
          <img
            :src="item.banner"
            style="border-radius: 6px;width: 100%; height: auto;"
          >
        </div>
        <a
          v-else
          :title="item.title"
          :href="item.url?item.url:'javascript:;'"
          :target="(item.target>0)?'_blank':'_self'"
        >
          <img
            :src="item.banner"
            style="border-radius: 6px;width: 100%; height: auto;"
          >
        </a>
      </swiper-slide>
      <div
        slot="button-prev"
        class="swiper-button-prev swiper-navBtn"
      />
      <div
        slot="button-next"
        class="swiper-button-next swiper-navBtn"
      />

      <div
        slot="pagination"
        class="swiper-pagination mb-1"
      />
    </swiper>

    <v-sheet
      rounded="pill"
      color="box_bg"
    >
      <v-chip-group
        v-model="selchip"
        mandatory
        color="primary"
        class="mb-1 px-2 rounded-lg font-weight-medium"
      >
        <v-chip
          value="lobby"
          color="app_bg"
          @click="changechip('lobby')"
        >
          <v-icon
            left
          >
            mdi-home
          </v-icon>
          {{ $t('dating') }}
        </v-chip>
        <v-chip
          value="hot"
          color="app_bg"
          @click="changechip('hot')"
        >
          <v-icon
            left
            color="red"
          >
            mdi-fire
          </v-icon>
          {{ $t('rmyx') }}
        </v-chip>
        <v-chip
          value="slots"
          color="app_bg"
          @click="changechip('slots')"
        >
          <v-icon
            left
            color="blue"
          >
            mdi-slot-machine
          </v-icon>
          {{ $t('slots') }}
        </v-chip>
        <v-chip
          value="fav"
          color="app_bg"
          to="/fav"
        >
          <v-icon
            left
            color="purple"
          >
            mdi-star-box
          </v-icon>
          {{ $t('scj') }}
        </v-chip>
      </v-chip-group>
    </v-sheet>

    <template v-if="selchip=='lobby'">
      <HomeLobby @changechip="changechip" />
    </template>
    <template v-if="selchip=='hot'">
      <HomeHot />
    </template>
    <template v-if="selchip=='slots'">
      <HomeSlots />
    </template>
  </v-container>
</template>


<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import store from "../store/"
import HomeLobby from '@/views/pages/Home_lobby.vue'
import HomeHot from '@/views/pages/Home_hot.vue'
import HomeSlots from '@/views/pages/Home_slots.vue'

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    HomeLobby,HomeHot,HomeSlots,
  },
  data: () => ({
    topBanners: [],
    selchip: 'lobby',
    pg_platform: 4,
    swiperOption: {
      slidesPerView: 1.2,
      centeredSlides: true,
      loop: true,
      spaceBetween: 8,
      fade: true,
      grabCursor: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 3,
          spaceBetween: 16,
          centeredSlides: false
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    ws: null,
    wstimer: null,
  }),
  computed: {

  },
  watch: {

  },
  created() {
    console.log('webp_feature:'+this.$store.state.webp_feature)
    this.home_slide()
    //this.ws = new WebSocket("ws://34.151.212.31:8282/?room=1")
    this.ws = new WebSocket("wss://wss.bonus.game/wss")
    this.ws.onopen = function() {}
    this.ws.onmessage = function(e) {
        //console.log("收到服务端的消息：" + e.data)
        store.commit('betlog', JSON.parse(e.data))
    }
  },
  mounted() {
    this.changechip('lobby')

    this.wstimer = setInterval(() => {
      this.ws.send(new Date().getTime())
    }, 30000)
  },
  beforeDestroy() {
    this.ws.close()
    clearInterval(this.wstimer)
  },
  methods: {
    home_slide() {
      let paramObj = {
        
      }
      this.$server.home_slide(paramObj).then((response) => {
        if(response.code==200){
          this.topBanners = response.data.ads
        }else{
          
        }
      })
    },
    changechip(selchip){
      this.selchip = selchip
    },
  }

}
</script>

<style>
.bgimg_bc {
    background: url('../assets/img/icons/coin-dollar.png') -45px center no-repeat;
    
}
</style>